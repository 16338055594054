import { Prop, Vue, Watch } from 'vue-property-decorator';
import Utils from '@/assets/js/utils/Utils';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetError, WidgetErrorType } from '@/components/widgets/components/WidgetErrorMessage.vue';

import type { DataResolver } from '@/components/widgets/data/DataResolver';
import { Resolution } from '@/modules/shared/types';
import { i18n } from '@/plugins/i18n';

export interface WidgetMenuAction {
    text: string;
    icon: string;
    action: () => void,
}

export interface WidgetSettings<D> {
    supportsCompare: boolean;
    supportsTable: boolean;
    enableTimeBar: boolean;
    enabledDataStreaming: boolean;
    dataResolver?: DataResolver<D>;
}

export class Widget<D = any> extends Vue {

    @Prop({ default: () => ({}) })
    public readonly widgetConfig!: WidgetConfig;

    @Prop({ default: false })
    public readonly expanded!: boolean;

    @Prop({ default: false })
    public readonly report!: boolean;

    @Prop({ default: false })
    public readonly loading!: boolean;

    protected renderWidget: boolean = true;
    private loadDataQueued: boolean = true;
    private pendingLoadData: Promise<void>|undefined = undefined;

    public static defaultSettings: WidgetSettings<any> = {
        supportsCompare: false,
        supportsTable: false,
        enableTimeBar: true,
        enabledDataStreaming: false,
        dataResolver: undefined,
    };

    public settings: WidgetSettings<D>;

    constructor(settings?: Partial<WidgetSettings<D>>) {
        super();
        this.settings = Utils.deepCopy(Widget.defaultSettings);
        if (settings) {
            Object.assign(this.settings, settings);
        }
    }

    public created(): void {
        // reload 10min widgets every 15 minutes
        if (!this.report && this.widget.resolution === Resolution.Min10) {
            setInterval(this.loadData, 1000 * 60 * 15);
        }
    }

    public mounted(): void {
        this.$emit('update:settings', this.settings);
        this.loadWidget();
    }

    @Watch('expanded')
    private onExpanded(): void {
        this.renderWidget = false;
        this.$nextTick(() => this.renderWidget = true);
    }

    @Watch('widget')
    private onWidgetChanged(): void {
        this.loadWidget();
    }

    protected get widget(): WidgetConfig {
        const copy = Utils.deepCopy(this.widgetConfig);
        return copy;
    }

    public async loadWidget(): Promise<void> {
        this.loadDataQueued = true;
        if (this.pendingLoadData) {
            await this.pendingLoadData;
        }
        // eslint-disable-next-line no-async-promise-executor
        this.pendingLoadData = new Promise(async (resolve) => {
            this.loadDataQueued = false;
            this.$emit('update:loading', true);
            await this.loadData();
            // has config been changed since we started loading data?
            if (!this.loadDataQueued) {
                // emit event in $nextTick after vue finished rendering
                this.$nextTick(() => this.$emit('update:loading', false));
            }
            resolve();
        });
    }

    protected async loadData(): Promise<void> {
        this.$emit('update:error', null);
        try {
            // validate
            const validationError = await this.validateConfig();
            if (this.loadDataQueued) {
                return;
            }
            if (validationError) {
                this.handleLoadDataError(validationError);
                return;
            }
            if (this.settings.dataResolver) {
                if (this.settings.enabledDataStreaming) {
                    this.renderWidget = true;
                }
                const fetchDataResult = await this.fetchData();
                if (this.loadDataQueued) {
                    return;
                }
                if (this.isWidgetError(fetchDataResult)) {
                    this.renderWidget = false;
                    this.handleLoadDataError(fetchDataResult as WidgetError);
                    return;
                }
                const processDataResult = await this.processData(fetchDataResult as D);
                if (this.loadDataQueued) {
                    return;
                }
                if (this.isWidgetError(processDataResult)) {
                    this.renderWidget = false;
                    this.handleLoadDataError(processDataResult as WidgetError);
                    return;
                }
            }
            this.renderWidget = true;
        } catch (e: any) {
            this.renderWidget = false;
            const error = await this.mapToWidgetError(e);
            this.handleLoadDataError(error);
        }
    }

    protected handleLoadDataError(error: WidgetError): void {
        this.$emit('update:error', error);
    }

    private async mapToWidgetError(e: any): Promise<WidgetError> {
        if (this.isWidgetError(e)) {
            // this is already a widget error object
            return e as WidgetError;
        }
        if (e.toString() === 'TypeError: Failed to fetch') {
            return {
                type: WidgetErrorType.Service,
                title: `504 | ${i18n.t('error-data.504.title').toString()}`,
                message: i18n.t('error-data.504.message', { error: 'Failed to fetch' }).toString(),
                err: e,
            };
        }
        const status = e.status || 'unknown';
        const title = i18n.t(`error-data.${status}.title`).toString();
        const message = i18n.t(`error-data.${status}.message`, { error: e }).toString();
        const error: WidgetError = {
            type: WidgetErrorType.Service,
            title: `${e.status || 500} | ${title}`,
            message: message,
            details: undefined,
            err: e,
        };
        // try getting some error details from the service result
        try {
            if (e.clone) {
                const details = await e.clone().text();
                if (details) {
                    error.details = details;
                }
            }
        } catch (ex) {
            // could not get error message
            error.details = e as unknown as string;
        }
        return error;
    }

    private isWidgetError(e: any): boolean {
        if (!e || typeof e !== 'object' || !e.type) {
            return false;
        }
        switch (e.type as WidgetErrorType) {
            case WidgetErrorType.NoDataSource: return true;
            case WidgetErrorType.IncompleteDataSources: return true;
            case WidgetErrorType.NoPermissionForMetric: return true;
            case WidgetErrorType.MissingLicenseFeature: return true;
            case WidgetErrorType.MissingMetrics: return true;
            case WidgetErrorType.InvalidConfig: return true;
            case WidgetErrorType.Service: return true;
            case WidgetErrorType.NoData: return true;
            case WidgetErrorType.Custom: return true;
            default: return false;
        }
    }

    protected async fetchData(): Promise<D|WidgetError> {
        const data = this.settings.dataResolver?.getWidgetData(this.widget);
        if (!data) {
            const error: WidgetError = { type: WidgetErrorType.NoData };
            throw error;
        }
        return data;
    }

    protected processData(data: D): Promise<void|WidgetError> {
        // implemented in derived class
        return Promise.resolve();
    }

    protected async validateConfig(): Promise<null|WidgetError> {
        // implemented in derived class
        return null;
    }
}
