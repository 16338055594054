
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Aggregation, Resolution, ResourceType } from '@/clients/dashboardapi/v2';
import Utils from '@/assets/js/utils/Utils';
import BubbleMenu from '@/components/basics/BubbleMenu.vue';
import WidgetTimeChooser from '@/components/widgets/components/WidgetTimeChooser.vue';
import type { WidgetError } from '@/components/widgets/components/WidgetErrorMessage.vue';
import WidgetErrorMessage from '@/components/widgets/components/WidgetErrorMessage.vue';
import WidgetLoading from '@/components/widgets/components/WidgetLoading.vue';
import ConfirmDlg from '@/modules/shared/components/dialogs/generic/ConfirmDlg.vue';
import { WidgetWizard, WidgetWizardTab } from '@/components/widget-wizard';
import DashboardWidgetInfo from '@/components/widgets/components/DashboardWidgetInfo.vue';
import CopyWidgetDlg from '@/modules/ctx-dashboard/components/widgets/CopyWidgetDlg.vue';
import InfoDlg from '@/modules/shared/components/dialogs/generic/InfoDlg.vue';
import LicenseGuard from '@/modules/shared/components/util/LicenseGuard.vue';
import { LicenseFeature, Role } from '@/modules/shared/types';
import MissingLicenseDlg from '@/modules/shared/components/dialogs/generic/MissingLicenseDlg.vue';
import TextInputDlg from '@/modules/shared/components/dialogs/generic/TextInputDlg.vue';

import type { Dashboard, Portfolio, WidgetConfig, WidgetConfigResource } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import { Widget, WidgetSettings, WidgetUtils } from '@/components/widgets';
import { Port } from '@/modules/ctx-dashboard/Port';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import NoteBox from '@/components/basics/NoteBox.vue';
import RoleGuard from '@/modules/shared/components/util/RoleGuard.vue';
import FileUtils from '@/assets/js/utils/FileUtils';
import { v4 as generateUUID } from 'uuid';
import WidgetLoader from '@/components/widgets/components/WidgetLoader.vue';
import CreateWidgetTemplateDlg from '@/modules/ctx-dashboard/components/widgets/CreateWidgetTemplateDlg.vue';
import DateUtils from '@/assets/js/utils/DateUtils';
import WidgetStreaming from '@/components/widgets/components/DashboardWidgetStreaming.vue';
import WidgetStreamingFailed from '@/components/widgets/components/DashboardWidgetStreamingFailed.vue';
import CreateDashboardDlg from '@/modules/ctx-dashboard/components/dashboards/CreateDashboardDlg.vue';

interface Action {
    text: string;
    icon: string;
    action: () => any;
}

@Component({
    components: {
        CreateDashboardDlg,
        WidgetStreamingFailed,
        WidgetStreaming,
        CreateWidgetTemplateDlg,
        WidgetLoader,
        RoleGuard,
        NoteBox,
        WidgetWizard,
        TextInputDlg,
        MissingLicenseDlg,
        LicenseGuard,
        CopyWidgetDlg,
        WidgetLoading,
        WidgetErrorMessage,
        InfoDlg,
        DashboardWidgetInfo,
        WidgetTimeChooser,
        BubbleMenu,
        ConfirmDlg,
    },
})
export default class DashboardWidget extends Vue {

    @Prop({ default: {} })
    public readonly config!: WidgetConfig;

    @Prop({ default: null })
    public readonly dashboardKey!: string|null;

    @Prop({ default: false })
    public readonly report!: boolean;

    @Ref('widgetLoader')
    private readonly refWidgetLoader!: any|undefined;

    private readonly LicenseFeature = LicenseFeature;
    private readonly Role = Role;
    private widgetInfo: string = '';

    private menuActions: Action[] = [];

    // actual copies are made in the created event, as accessing the props right here will cause unintended
    // strange behavior
    // writable copy of config used for editing
    private widget: WidgetConfig = {} as WidgetConfig;
    // copy used to make temporary changes that should not appear in the editor
    private widgetOverride: WidgetConfig = {} as WidgetConfig;

    private showMissingDataDlg: boolean = false;
    private showCopyDlg: boolean = false;
    private showMissingLicenseDlg: boolean = false;
    private showDeleteConfirmDlg: boolean = false;
    private showCreateTemplateDlg: boolean = false;
    private showWizardDlg: boolean = false;
    private wizardTab: WidgetWizardTab|null = null;
    private wizardTabAction: string|null = null;
    private showDropdown: boolean = false;
    private showInfo: boolean = false;

    private dataIntegrity: number = 100;
    private expand: boolean = false;
    private isFavorite: boolean = false;
    private widgetSettings: WidgetSettings<any> = Widget.defaultSettings;

    private error: WidgetError|null = null;
    private loading: boolean = true;

    private portfolio: Portfolio|null = null;
    private dashboard: Dashboard|null = null;

    private showRetryStreamingDlg: boolean = false;

    public created(): void {
        this.onWidgetConfigChanged();
        this.fetchPortfolio();
        this.fetchDashboard();
    }

    public mounted(): void {
        this.applyUrlOverrides();
    }

    private async fetchPortfolio(): Promise<void> {
        this.portfolio = await Port.portfolios.getPortfolio(this.widget.portfolioKey);
    }

    private async fetchDashboard(): Promise<void> {
        this.dashboard = await Port.dashboards.getDashboard(this.widget.dashboardKey);
    }

    private get portfolioName(): string {
        return this.portfolio?.name || 'Unbekanntes Portfolio';
    }

    private get dashboardName(): string {
        return this.dashboard?.name || 'Unbekannte Auswertung';
    }

    private get title(): string {
        return this.widget.title
            || this.widget.generatedTitle
            || WidgetUtils.getDefaultWidgetTitle(this.widget) // TODO remove from context
            || this.$t(`widgets.title.for-preset.${this.widget.preset}`).toString();
    }

    private get useHeaderBackground(): boolean {
        switch (this.widget.type) {
            case WidgetType.Map: return true;
            case WidgetType.WeatherMap: return true;
            default: return false;
        }
    }

    @Watch('config')
    private async onWidgetConfigChanged(): Promise<void> {
        this.widget = Utils.deepCopy(this.config);
        this.widgetOverride = Utils.deepCopy(this.widget);
        this.isFavorite = await Port.widgets.isFavoriteWidget(this.widget.key);
        this.applyUrlOverrides();
    }

    private overrideTimeInterval(interval?: { name: string; from: Date; to: Date; resolution?: Resolution }): void {
        const config = Utils.deepCopy(this.widgetOverride);
        if (interval && interval.from && interval.to) {
            // override
            config.intervalName = interval.name;
            config.intervalFrom = interval.from;
            config.intervalTo = interval.to;
            if (interval.resolution) {
                config.resolution = interval.resolution;
            }
        } else {
            // reset to widget default
            config.intervalName = this.widget.intervalName;
            config.intervalFrom = this.widget.intervalFrom;
            config.intervalTo = this.widget.intervalTo;
            config.resolution = this.widget.resolution;
        }
        this.widgetOverride = config;
    }

    private async overrideDataSources(resources: WidgetConfigResource[]): Promise<void> {
        const config = Utils.deepCopy(this.widgetOverride);
        const resolvedResources = await Promise.all(resources.map(async (res) => {
            const generator = await Port.generators.getGeneratorByKey(res.resourceKey || '');
            res.resourceName = generator?.name || res.resourceName;
            return res;
        }));
        if (resolvedResources.length > 0) {
            // override
            const metrics = config.axis.flatMap((axis) => axis.metrics);
            metrics.forEach((metric) => {
                // anon resources are kept always
                const compareResources = metric.resources.filter((r) => r.resourceFilters !== undefined);
                if (resolvedResources.length === 1) {
                    // time compares for non-anon resources shall be kept for the new resources
                    // if we have more than x new resources, we omit the time compares, to make sure the total amount
                    // of resources does not get to high
                    const timeOverrides: (string|undefined)[] = metric.resources
                        .filter((r) => !r.resourceFilters)
                        .map((r) => r.timeOverride)
                        .filter(ArrayUtils.removeDuplicates);
                    const res: WidgetConfigResource[] = resolvedResources
                        .flatMap((r) => timeOverrides
                            .map((t) => ({ ...r, timeOverride: t })));
                    metric.resources = res.concat(compareResources);
                } else {
                    metric.resources = Utils.deepCopy(resolvedResources).concat(compareResources);
                }
            });
            metrics.forEach((metric) => metric.resources = metric.resources.map((res) => ({
                ...res,
                seriesName: WidgetUtils.getDefaultSeriesName(config, metric, res),
            })));
        } else {
            // reset to widget default
            config.axis
                .forEach((axis, axisIndex) => axis.metrics
                    .forEach((metric, metricIndex) => metric.resources = this.widget.axis[axisIndex].metrics[metricIndex].resources));
        }
        this.widgetOverride = config;
    }

    @Watch('showDropdown')
    private updateWidgetActions() {
        this.menuActions = this.refWidgetLoader.getMenuActions();
    }

    @Watch('$i18n.locale')
    private onLocaleChanged() {
        this.widgetOverride = Utils.deepCopy(this.widgetOverride);
    }

    @Watch('$route')
    private onRouteChanged() {
        this.showDropdown = false;
    }

    private updateWidgetSettings(settings: WidgetSettings<any>) {
        this.widgetSettings = settings;
    }

    @Watch('$route.query')
    private applyUrlOverrides(): void {
        // from and to should be provided in format yyyy-mm-dd
        const intervalName = this.$route.query.intervalname as string|undefined;
        const resolution = this.$route.query.resolution as Resolution|undefined;
        let from = new Date(this.$route.query.from as string|undefined || '');
        from = DateUtils.cropToPrecision(from, 'days');
        let to = new Date(this.$route.query.to as string|undefined || '');
        to = DateUtils.cropToPrecision(to, 'days');

        if (intervalName && intervalName !== 'fixed') {
            this.overrideTimeInterval({
                name: intervalName,
                from: new Date(),
                to: new Date(),
                resolution: resolution,
            });
        } else if (from && to && to > from) {
            // only apply time range if it is valid
            this.overrideTimeInterval({
                name: 'fixed',
                from: from,
                to: to,
                resolution: resolution,
            });
        } else {
            this.overrideTimeInterval(undefined);
        }

        let generatorKeys: string[] = [];
        if (this.$route.query.generator) {
            if (Array.isArray(this.$route.query.generator)) {
                generatorKeys = this.$route.query.generator as string[];
            } else {
                generatorKeys = [this.$route.query.generator as string];
            }
        }
        this.overrideDataSources(generatorKeys
            .map((generatorKey) => ({
                uuid: generateUUID().substring(0, 8),
                type: ResourceType.Generator,
                resourceName: generatorKey,
                resourceKey: generatorKey,
                seriesName: generatorKey,
                aggregationOverGenerators: Aggregation.None,
                config: {},
            })));
    }

    private copy() {
        this.showDropdown = false;
        this.showInfo = false;
        if (this.$store.getters['User/hasFeatureLicense'](LicenseFeature.ADVANCED)) {
            this.showCopyDlg = true;
        } else {
            this.showMissingLicenseDlg = true;
        }
    }

    private showCreateTemplateDialog() {
        this.showDropdown = false;
        this.showInfo = false;
        if (this.$store.getters['User/hasFeatureLicense'](LicenseFeature.ADVANCED)) {
            this.showCreateTemplateDlg = true;
        } else {
            this.showMissingLicenseDlg = true;
        }
    }

    private edit() {
        this.openWizardInTab(undefined);
    }

    private changeTime() {
        this.openWizardInTab(WidgetWizardTab.Time);
    }

    private openWizardInTab(tab?: WidgetWizardTab, action?: string) {
        this.showDropdown = false;
        this.wizardTab = tab || null;
        this.wizardTabAction = action || null;
        this.showWizardDlg = true;
    }

    private handleError(action: string) {
        if (action === 'license') {
            this.showMissingLicenseDlg = true;
        } else {
            this.openWizardInTab(action as WidgetWizardTab);
        }
    }

    private async exportConfig(): Promise<void> {
        this.showDropdown = false;
        const filename = `${this.widget.title || this.widget.generatedTitle}-${generateUUID().substring(0, 8)}.json`;
        FileUtils.downloadTextAsFile(filename, JSON.stringify(this.widget, null, 4));
    }

    private performAction(callback: () => void) {
        this.showDropdown = false;
        this.showInfo = false;
        callback();
        this.updateWidgetActions();
    }

    /**
     * Helper function that toogles fullscreen. Also removes chart from dom throuh chartloading property, to avoid
     * laggy chart resize.
     */
    private toggleFullscreen() {
        this.showDropdown = false;
        this.$nextTick(() => {
            this.expand = !this.expand;
            if (this.expand) {
                Utils.disableScrolling();
                const title = this.$refs['widget-title'] as HTMLElement;
                if (title) {
                    title.focus();
                }
            } else {
                Utils.enableScrolling();
            }
        });
    }

    private async addFavorit() {
        this.showDropdown = false;
        await Port.widgets.addWidgetToFavorites(this.widget);
        this.isFavorite = true;
    }

    private async removeFavorit() {
        this.showDropdown = false;
        await Port.widgets.removeWidgetFromFavorites(this.widget);
        this.isFavorite = false;
    }

    private async updateWidget(widget: WidgetConfig) {
        await Port.widgets.updateWidget(widget);
        this.widget = widget;
        this.widgetOverride = this.widget;
        this.showWizardDlg = false;
    }

    private async deleteWidget(): Promise<void> {
        this.showDropdown = false;
        this.showInfo = false;
        if (!await Port.user.getUserHasLicenseFeature(LicenseFeature.ADVANCED)) {
            this.showMissingLicenseDlg = true;
        } else {
            this.showDeleteConfirmDlg = true;
        }
        this.updateWidgetActions();
    }

    private async confirmDeleteWidget() {
        await Port.widgets.deleteWidget(this.widget);
        this.showDeleteConfirmDlg = false;
    }

    private openRetryStreamingDlg() {
        this.showRetryStreamingDlg = true;
    }

    private retryStreaming() {
        this.refWidgetLoader.getWidget().loadWidget();
        this.showRetryStreamingDlg = false;
    }
}

