
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Port } from '@/modules/ctx-dashboard/Port';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import InputText from '@/modules/shared/components/form/InputText.vue';
import MissingLicenseDlg from '@/modules/shared/components/dialogs/generic/MissingLicenseDlg.vue';

interface Template {
    key?: string;
    name: string;
    description: string;
}

@Component({
    components: {
        MissingLicenseDlg,
        InputText,
        LoadingAnimationSmall,
        BaseDialog,
    },
})
export default class CreateDashboardDlg extends Vue {

    @Prop({ required: true, default: false })
    public readonly open!: boolean;

    @Prop({ required: true })
    public readonly portfolioKey!: string|null;

    @Ref('inputName')
    private readonly inputNameRef: HTMLInputElement|undefined;

    private templates: Template[] = [];
    private showTemplateSelection: boolean|null = null;
    private template: Template|null = null;
    private name: string = '';
    private loading: boolean = false;
    private errors: {[key: string]: string[]} = {};

    public created() {
        this.fetchTemplates();
        Port.templates.onTemplatesChange(() => this.fetchTemplates());
    }

    @Watch('open')
    private async onOpened(): Promise<void> {
        this.$nextTick(() => {
            if (this.inputNameRef) {
                this.inputNameRef.focus();
            }
        });
        if (this.open) {
            await this.fetchTemplates();
            await this.validate();
        }
    }

    private get hasErrors(): boolean {
        return Object.keys(this.errors).length > 0;
    }

    private get templateName(): string {
        return this.template?.name || this.$t('ctx-dashboard.create-dashboard.no-template').toString();
    }

    private get templateDescription(): string {
        return this.template?.description || this.$t('ctx-dashboard.create-dashboard.template.no-desc').toString();
    }

    private close(): void {
        this.$emit('update:open', false);
        this.name = '';
        this.template = this.templates[0] || null;
    }

    private async submit(): Promise<void> {
        if (this.hasErrors || !this.portfolioKey) {
            return;
        }
        this.loading = true;
        const dashboard = await Port.dashboards.createDashboard(this.portfolioKey, this.name, this.template?.key)
            .finally(() => this.loading = false);
        this.close();
    }

    @Watch('name')
    @Watch('template')
    private async validate(): Promise<void> {
        if (this.portfolioKey) {
            this.errors = await Port.dashboards.validateDashboard({ portfolioKey: this.portfolioKey, name: this.name });
        } else {
            this.errors = {};
        }
    }

    private async fetchTemplates(): Promise<void> {
        const templates = await Port.templates.getDashboardTemplates();
        this.templates = templates.map((t) => ({
            key: t.key,
            name: t.name,
            description: t.description || this.$t('ctx-dashboard.create-dashboard.template.no-desc').toString(),
        }));
        this.template = this.templates[0] || null;
    }
}
