export enum Units {
    MetersPerSecond = 'm/s',
    RevolutionsPerMinute = 'rpm',
    Kilowatt = 'kW',
    Degree = '°',
    KilowattHour = 'kWh',
    DegreeCelsius = '°C',
    KilovoltAmpereHour = 'kVAh',
    Volt = 'V',
    Ampere = 'A',
    Hertz = 'Hz',
    Hour = 'h',
    Percentage = '%',
    MillimetersPerHour = 'mm/h',
    GramsPerCubicMeter = 'g/m3',
    Bar = 'bar',
    Euro = 'EUR',
    EuroSymbol = '€',
    Millisecond = 'ms',
    CentsPerKilowattHour = 'ct/kWh',
    Gram = 'g',
    Meter = 'm',
    KilowattHoursPerSquareMeter = 'kWh/m2',
}
