import { i18n } from '@/plugins/i18n';
import Utils from '@/assets/js/utils/Utils';
import DateUtils from '@/assets/js/utils/DateUtils';
import { Units } from '@/assets/js/utils/Units';

export default class Formatter {
    private static readonly DEFAULT_MAX_DIGITS = 2;
    private static readonly unitToMaxDigitsHashMap: Map<string, number> = new Map([
        [Units.MetersPerSecond, 2],
        [Units.RevolutionsPerMinute, 2],
        [Units.Kilowatt, 0],
        [Units.Degree, 2],
        [Units.KilowattHour, 0],
        [Units.DegreeCelsius, 2],
        [Units.KilovoltAmpereHour, 2],
        [Units.Volt, 2],
        [Units.Ampere, 2],
        [Units.Hertz, 2],
        [Units.Hour, 2],
        [Units.Percentage, 2],
        [Units.MillimetersPerHour, 1],
        [Units.GramsPerCubicMeter, 2],
        [Units.Bar, 2],
        [Units.Euro, 0],
        [Units.EuroSymbol, 0],
        [Units.Millisecond, 2],
        [Units.CentsPerKilowattHour, 3],
        [Units.Gram, 2],
        [Units.Meter, 2],
        [Units.KilowattHoursPerSquareMeter, 2],
    ]);

    /**
     * Formats the time with the specified format. If no explicit format is passed 'dd.MM.yyyy' is used as default.
     * The formatter replaces supported placeholders with the values from the passed time. Time can be passed as any
     * type, that can be passed to new Date().
     *
     * Supported placeholders are:
     *
     * dd - 2 digits Day of month
     * MM - 2 digits Month
     * MMM - short month name
     * MMMM - full month name
     * yyyy - full year
     * hh - 2 digits hour
     * mm - 2 digits minute
     * ss - 2 digits second
     *
     * @param time the time to format as any type that can be used with new Date()
     * @param format date time format, default: 'dd.MM.yyyy'
     * @param placeholder the string to display if there is no time
     */
    public static formatDate(time?: number|string|Date, format: string = 'dd.MM.yyyy', placeholder: string = ''): string {
        if (time === undefined || time === null || time === 0) {
            return placeholder;
        }

        const timeZone = process.env.VUE_APP_DEFAULT_TIMEZONE || 'Europe/Berlin';
        const date = Utils.convertTimeZone(new Date(time), timeZone);

        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth() is 0 based
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        return Formatter.format(format, { day, month, year, hours, minutes, seconds });
    }

    public static formatTimeInterval(from: Date = new Date(), to: Date = new Date(), short = true, exclusiveEndDate = true): string {
        if (DateUtils.isSingleFullYear(from, to)) {
            if (short) {
                return from.getFullYear().toString();
            }
            const fromFormatted = Formatter.formatDate(from, 'MMMM');
            const toDisplay = new Date(to.getTime());
            toDisplay.setDate(toDisplay.getDate() - 1);
            const toFormatted = Formatter.formatDate(toDisplay, 'MMMM yyyy');
            return `${fromFormatted} - ${toFormatted}`;
        }
        if (DateUtils.isSingleFullMonth(from, to)) {
            const dateFormat = short ? 'MMM. yyyy' : 'MMMM yyyy';
            return Formatter.formatDate(from, dateFormat);
        }
        let dateFormatFrom = 'dd.MM.yyyy';
        let dateFormatTo = 'dd.MM.yyyy';
        if (DateUtils.isMultipleFullMonths(from, to)) {
            if (from.getFullYear() === to.getFullYear()) {
                dateFormatFrom = short ? 'MMM.' : 'MMMM';
            } else {
                dateFormatFrom = short ? 'MMM. yyyy' : 'MMMM yyyy';
            }
            dateFormatTo = short ? 'MMM. yyyy' : 'MMMM yyyy';
        }
        const fromFormatted = Formatter.formatDate(from, dateFormatFrom);
        const toDisplay = new Date(to.getTime());
        // map to inclusive
        if (exclusiveEndDate) {
            toDisplay.setDate(toDisplay.getDate() - 1);
        }
        const toFormatted = Formatter.formatDate(toDisplay, dateFormatTo);
        if (fromFormatted === toFormatted) {
            return `${fromFormatted}`;
        }
        return `${fromFormatted} - ${toFormatted}`;
    }

    /**
     * Formats the duration with the specified format. If no explicit format is passed 'hh:mm' is used as default.
     * The formatter replaces supported placeholders with the values from the passed time. Time can be passed as any
     * type, that can be passed to new Date().
     *
     * Supported placeholders are:
     *
     * /d - 1 digit day
     * /dd - 2 digits days
     * /h - 1 digit hour
     * /hh - 2 digits hour
     * /m - 1 digits minute
     * /mm - 2 digits minute
     * /s - 1 digits second
     * /ss - 2 digits second
     *
     * @param millis time in millis
     * @param format format, default: 'hh:mm'
     * @param placeholder return value when input is undefined or null
     */
    public static formatDuration(millis?: number, format?: string, placeholder?: string): string {
        if (millis === undefined || millis === null) {
            return placeholder || '';
        }

        let seconds = Math.floor((millis / 1000) % 60);
        let minutes = Math.floor((millis / 1000 / 60) % 60);
        // let hours = Math.floor((millis / 1000 / 60 / 60));
        let hours = Math.floor((millis / 1000 / 60 / 60) % 24);
        let day = Math.floor((millis / 1000 / 60 / 60 / 24));

        if (format) {
            if (!format.includes('dd')) {
                hours += day * 24;
            }
            if (!format.includes('hh')) {
                minutes += hours * 60;
            }
            if (!format.includes('mm')) {
                seconds += minutes * 60;
            }
        } else {
            // for debugging the math
            // format = `${millis} => ${day}-${hours}-${minutes}-${seconds}`

            // we use hours, minutes and seconds standalone here, so we have to convert them to absolute values,
            // eg. 1 day and 10 hours would otherwise render 10 hours instead of 34 hours
            hours += day * 24;
            minutes += hours * 60;
            seconds += minutes * 60;

            const round = true;
            if (day >= 2) {
                day = (round && hours > 12) ? day + 1 : day;
                format = `d "${i18n.tc('day', day)}"`;
            } else if (hours >= 2) {
                hours = (round && minutes > 30) ? hours + 1 : hours;
                format = `h "${i18n.tc('hour', hours)}"`;
            } else if (minutes >= 2) {
                minutes = (round && seconds > 30) ? minutes + 1 : minutes;
                format = `m "${i18n.tc('minute', minutes)}"`;
            } else {
                format = `s "${i18n.tc('second', seconds)}"`;
            }
        }

        // Formatting a value like minutes will result in the leading m and trailing s being replaced with minute and
        // second. To allow words in the format string, parts of the string that should not go through the replacer
        // can be escaped with "
        const startsWithEscapedString = format.trim().startsWith('"') ? 0 : 1;
        return format.split('"')
            .map((part, index) => {
                if (index % 2 === startsWithEscapedString) {
                    return part;
                }
                return Formatter.format(part, { day, hours, minutes, seconds });
            })
            .reduce((result, part) => `${result}${part}`);
    }

    private static format(format: string, values: { day?: number; month?: number; year?: number; hours?: number; minutes?: number; seconds?: number}) {
        const v = {
            day: values.day || 0,
            month: values.month || 0,
            year: values.year || 0,
            hours: values.hours || 0,
            minutes: values.minutes || 0,
            seconds: values.seconds || 0,
        };

        let result = format;
        result = result.replace('dd', v.day < 10 ? `0${v.day}` : `${v.day}`);
        // result = result.replace('d', `${v.day}`);
        result = result.replace('MMMM', this.monthname(v.month, false));
        result = result.replace('MMM', this.monthname(v.month, true));
        result = result.replace('MM', v.month < 10 ? `0${v.month}` : `${v.month}`);
        // result = result.replace('M', `${v.month}`);
        result = result.replace('yyyy', v.year < 100 ? `19${v.year}` : `${v.year}`);
        result = result.replace('hh', v.hours < 10 ? `0${v.hours}` : `${v.hours}`);
        // result = result.replace('h', `${v.hours}`);
        result = result.replace('mm', v.minutes < 10 ? `0${v.minutes}` : `${v.minutes}`);
        // result = result.replace('m', `${v.minutes}`);
        result = result.replace('ss', v.seconds < 10 ? `0${v.seconds}` : `${v.seconds}`);
        // result = result.replace('s', `${v.seconds}`);
        return result;
    }

    private static monthname(month: number, short: boolean = false): string {
        switch (month) {
            case 1: return i18n.tc('jan', short ? 1 : 0).toString();
            case 2: return i18n.tc('feb', short ? 1 : 0).toString();
            case 3: return i18n.tc('mar', short ? 1 : 0).toString();
            case 4: return i18n.tc('apr', short ? 1 : 0).toString();
            case 5: return i18n.tc('may', short ? 1 : 0).toString();
            case 6: return i18n.tc('jun', short ? 1 : 0).toString();
            case 7: return i18n.tc('jul', short ? 1 : 0).toString();
            case 8: return i18n.tc('aug', short ? 1 : 0).toString();
            case 9: return i18n.tc('sep', short ? 1 : 0).toString();
            case 10: return i18n.tc('oct', short ? 1 : 0).toString();
            case 11: return i18n.tc('nov', short ? 1 : 0).toString();
            case 12: return i18n.tc('dec', short ? 1 : 0).toString();
            default: return month.toString();
        }
    }

    public static formatMaxDigitsForUnit(number?: number, unit?: string, placeholder: string = '', roundToSteps: boolean = false) {
        return Formatter.formatMaxDigits(number, Formatter.digitsForUnit(unit), placeholder, roundToSteps);
    }

    public static digitsForUnit(unit?: string): number {
        if (!unit) {
            return Formatter.DEFAULT_MAX_DIGITS;
        }

        return this.unitToMaxDigitsHashMap.get(unit) || Formatter.DEFAULT_MAX_DIGITS;
    }

    /**
     * Crops the decimal places of the given number to a max count. Default is 2.
     * @param number numeric value
     * @param digits max digits, default: 2
     * @param placeholder return value when input is undefined or null
     * @param roundToSteps whether to round the formatted value even more to a more readable step size
     */
    public static formatMaxDigits(number?: number, digits: number = 2, placeholder: string = '', roundToSteps: boolean = false): string {
        if (number === undefined || number === null || Number.isNaN(number)) {
            return placeholder;
        }
        try {
            let rounded = number.toFixed(digits);
            if (roundToSteps) {
                rounded = Formatter.formatWithSteps(rounded);
            }
            let formatted = Formatter.formatNumber(+parseFloat(rounded));
            if (digits > 0) {
                let missingDigits = digits;
                if (formatted.includes(',')) {
                    missingDigits = digits - formatted.substring(formatted.indexOf(',') + 1).length;
                } else {
                    formatted += ',';
                }
                for (let i = 0; i < missingDigits; i++) {
                    formatted += '0';
                }
            }
            return formatted;
        } catch (e: any) {
            return number.toString();
        }
    }

    private static formatWithSteps(value: string) {
        // Determine the number of digits after the decimal point
        const decimalPart = value.split('.')[1] || '';
        const digitsAfterDecimal = decimalPart.length;
        if (digitsAfterDecimal === 0) {
            return value;
        }

        // Apply the appropriate step rounding based on the number of digits
        let step: number;
        if (digitsAfterDecimal === 1) {
            step = 0.5; // 1 digit: step 0.5
        } else if (digitsAfterDecimal === 2) {
            step = 0.25; // 2 digits: step 0.25
        } else if (digitsAfterDecimal === 3) {
            step = 0.025; // 3 digits: step 0.025
        } else {
            step = 0.0025; // 4 or more digits: step 0.0025
        }

        // Round the formatted value to the nearest step
        const valueWithStepsize = Math.round(parseFloat(value) / step) * step;
        return valueWithStepsize.toString();
    }
    /**
     * Formats a number using , for decimals and . for thousands.
     * example 4000.5 => 4.000,5
     * @param number numeric value
     * @param placeholder return value when input is undefined or null
     */
    public static formatNumber(number?: number, placeholder?: string): string {
        if (number === undefined || number === null) {
            return placeholder || '';
        }
        try {
            const result = number.toString().replace('.', ',').split(',');
            // add 1000 divider only to part before the ,
            result[0] = result[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return result.join(',');
        } catch (e: any) {
            return number.toString();
        }
    }

    /**
     * Formats a power metric using MW instead of kW if value is > 10.000
     * @param power numeric value
     * @param placeholder return value when input is undefined or null
     */
    public static formatPower(power?: number, placeholder?: string): string {
        if (power === undefined || power === null) {
            return placeholder || '';
        }
        if (Number.isNaN(power)) {
            return power.toString();
        }
        if (power < 10_000) {
            return `${Formatter.formatMaxDigitsForUnit(power, Units.Kilowatt)} kW`;
        }
        return `${Formatter.formatMaxDigitsForUnit(power / 1000, Units.Kilowatt)} MW`;
    }

    /**
     * Formats a zipcode to 5 digits, by adding 0s if the code is shorter
     * @param zipcode the zipcode to format
     * @param placeholder return value when input is undefined or null
     */
    public static formatZipcode(zipcode?: number|string, placeholder?: string): string {
        if (zipcode === undefined || zipcode === null) {
            return placeholder || '';
        }
        let result: string = zipcode.toString(10);
        while (result.length < 5) {
            result = `0${result}`;
        }
        return result;
    }

    /**
     * Formats a value based on the unit. Some units, eg currencies are formatted in a special way. The $ unit is
     * displayed before the value, € behind without space, all other units are displayed behind the value with a space.
     * The value itself will not be formatted
     * @param value formatted value
     * @param unit metric unit
     */
    public static formatUnit(value: any, unit?: string): string {
        if (value === undefined || value === null) {
            return '';
        }
        if (!unit) {
            return value;
        }
        switch (unit.toLowerCase()) {
            case '$': return `$${value.toString()}`;
            case '€': return `${value.toString()}€`;
            case 'kw': return Formatter.formatPower(parseFloat(value));
            default: return `${value.toString()} ${unit}`;
        }
    }
}
