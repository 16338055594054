/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChartDataRepresentation,
    ChartDataRepresentationFromJSON,
    ChartDataRepresentationToJSON,
    ChartDataRequest,
    ChartDataRequestFromJSON,
    ChartDataRequestToJSON,
    DeleteReImportRequest,
    DeleteReImportRequestFromJSON,
    DeleteReImportRequestToJSON,
    GaugeDataRepresentation,
    GaugeDataRepresentationFromJSON,
    GaugeDataRepresentationToJSON,
    GaugeDataRequest,
    GaugeDataRequestFromJSON,
    GaugeDataRequestToJSON,
    LatestLogDataRequest,
    LatestLogDataRequestFromJSON,
    LatestLogDataRequestToJSON,
    LogDataRepresentation,
    LogDataRepresentationFromJSON,
    LogDataRepresentationToJSON,
    LogDataRequest,
    LogDataRequestFromJSON,
    LogDataRequestToJSON,
    OperatorPrognosisMonetaryRepresentation,
    OperatorPrognosisMonetaryRepresentationFromJSON,
    OperatorPrognosisMonetaryRepresentationToJSON,
    OperatorPrognosisMonetaryRequest,
    OperatorPrognosisMonetaryRequestFromJSON,
    OperatorPrognosisMonetaryRequestToJSON,
    PieDataRepresentation,
    PieDataRepresentationFromJSON,
    PieDataRepresentationToJSON,
    PieDataRequest,
    PieDataRequestFromJSON,
    PieDataRequestToJSON,
    PowercurveDataRepresentation,
    PowercurveDataRepresentationFromJSON,
    PowercurveDataRepresentationToJSON,
    PowercurveDataRequest,
    PowercurveDataRequestFromJSON,
    PowercurveDataRequestToJSON,
    ReImportMarkerRepresentation,
    ReImportMarkerRepresentationFromJSON,
    ReImportMarkerRepresentationToJSON,
    ReImportRequest,
    ReImportRequestFromJSON,
    ReImportRequestToJSON,
    ScatterDataRepresentation,
    ScatterDataRepresentationFromJSON,
    ScatterDataRepresentationToJSON,
    ScatterDataRequest,
    ScatterDataRequestFromJSON,
    ScatterDataRequestToJSON,
    WindroseDataRepresentation,
    WindroseDataRepresentationFromJSON,
    WindroseDataRepresentationToJSON,
    WindroseDataRequest,
    WindroseDataRequestFromJSON,
    WindroseDataRequestToJSON,
} from '../models';

export interface CreateDataReImportRequest {
    reImportRequest: ReImportRequest;
}

export interface DeleteDataReImportsRequest {
    deleteReImportRequest: DeleteReImportRequest;
}

export interface GetChartDataRequest {
    chartDataRequest: ChartDataRequest;
}

export interface GetChartDataAsCSVRequest {
    chartDataRequest: ChartDataRequest;
}

export interface GetGaugeDataRequest {
    gaugeDataRequest: GaugeDataRequest;
}

export interface GetLatestLogDataRequest {
    latestLogDataRequest: LatestLogDataRequest;
}

export interface GetLogDataRequest {
    logDataRequest: LogDataRequest;
}

export interface GetOperatorPrognosisMonetaryRequest {
    operatorPrognosisMonetaryRequest: OperatorPrognosisMonetaryRequest;
}

export interface GetPieDataRequest {
    pieDataRequest: PieDataRequest;
}

export interface GetPowercurveDataRequest {
    powercurveDataRequest: PowercurveDataRequest;
}

export interface GetScatterDataRequest {
    scatterDataRequest: ScatterDataRequest;
}

export interface GetWindroseDataRequest {
    windroseDataRequest: WindroseDataRequest;
}

export interface UploadCSVDataRequest {
    type: UploadCSVDataTypeEnum;
    data: Blob;
}

export interface UploadSolarParkDataRequest {
    parkMasterData: Blob;
    parkDataMonthly: Blob;
    parkDataDaily: Blob;
}

/**
 * 
 */
export class DataApi extends runtime.BaseAPI {

    /**
     * Mark an interval for reimport
     * Mark an interval for reimport
     */
    async createDataReImportRaw(requestParameters: CreateDataReImportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reImportRequest === null || requestParameters.reImportRequest === undefined) {
            throw new runtime.RequiredError('reImportRequest','Required parameter requestParameters.reImportRequest was null or undefined when calling createDataReImport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/data/reimports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReImportRequestToJSON(requestParameters.reImportRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark an interval for reimport
     * Mark an interval for reimport
     */
    async createDataReImport(reImportRequest: ReImportRequest): Promise<void> {
        await this.createDataReImportRaw({ reImportRequest: reImportRequest });
    }

    /**
     * Delete list of reimport markers
     * Delete reimport markers
     */
    async deleteDataReImportsRaw(requestParameters: DeleteDataReImportsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteReImportRequest === null || requestParameters.deleteReImportRequest === undefined) {
            throw new runtime.RequiredError('deleteReImportRequest','Required parameter requestParameters.deleteReImportRequest was null or undefined when calling deleteDataReImports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/data/reimports`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteReImportRequestToJSON(requestParameters.deleteReImportRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete list of reimport markers
     * Delete reimport markers
     */
    async deleteDataReImports(deleteReImportRequest: DeleteReImportRequest): Promise<void> {
        await this.deleteDataReImportsRaw({ deleteReImportRequest: deleteReImportRequest });
    }

    /**
     * Returns chart data for datarequest
     * Returns chart data
     */
    async getChartDataRaw(requestParameters: GetChartDataRequest): Promise<runtime.ApiResponse<Array<ChartDataRepresentation>>> {
        if (requestParameters.chartDataRequest === null || requestParameters.chartDataRequest === undefined) {
            throw new runtime.RequiredError('chartDataRequest','Required parameter requestParameters.chartDataRequest was null or undefined when calling getChartData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/chart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChartDataRequestToJSON(requestParameters.chartDataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChartDataRepresentationFromJSON));
    }

    /**
     * Returns chart data for datarequest
     * Returns chart data
     */
    async getChartData(chartDataRequest: ChartDataRequest): Promise<Array<ChartDataRepresentation>> {
        const response = await this.getChartDataRaw({ chartDataRequest: chartDataRequest });
        return await response.value();
    }

    /**
     * Returns chart data as CSV export
     * Returns chart data as CSV
     */
    async getChartDataAsCSVRaw(requestParameters: GetChartDataAsCSVRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.chartDataRequest === null || requestParameters.chartDataRequest === undefined) {
            throw new runtime.RequiredError('chartDataRequest','Required parameter requestParameters.chartDataRequest was null or undefined when calling getChartDataAsCSV.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/csv/chart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChartDataRequestToJSON(requestParameters.chartDataRequest),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns chart data as CSV export
     * Returns chart data as CSV
     */
    async getChartDataAsCSV(chartDataRequest: ChartDataRequest): Promise<Blob> {
        const response = await this.getChartDataAsCSVRaw({ chartDataRequest: chartDataRequest });
        return await response.value();
    }

    /**
     * Returns gauge data for datarequest
     * Returns gauge data
     */
    async getGaugeDataRaw(requestParameters: GetGaugeDataRequest): Promise<runtime.ApiResponse<GaugeDataRepresentation>> {
        if (requestParameters.gaugeDataRequest === null || requestParameters.gaugeDataRequest === undefined) {
            throw new runtime.RequiredError('gaugeDataRequest','Required parameter requestParameters.gaugeDataRequest was null or undefined when calling getGaugeData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/gauge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GaugeDataRequestToJSON(requestParameters.gaugeDataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GaugeDataRepresentationFromJSON(jsonValue));
    }

    /**
     * Returns gauge data for datarequest
     * Returns gauge data
     */
    async getGaugeData(gaugeDataRequest: GaugeDataRequest): Promise<GaugeDataRepresentation> {
        const response = await this.getGaugeDataRaw({ gaugeDataRequest: gaugeDataRequest });
        return await response.value();
    }

    /**
     * Returns log data for datarequest
     * Returns latest log entries
     */
    async getLatestLogDataRaw(requestParameters: GetLatestLogDataRequest): Promise<runtime.ApiResponse<Array<LogDataRepresentation>>> {
        if (requestParameters.latestLogDataRequest === null || requestParameters.latestLogDataRequest === undefined) {
            throw new runtime.RequiredError('latestLogDataRequest','Required parameter requestParameters.latestLogDataRequest was null or undefined when calling getLatestLogData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/log/latest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LatestLogDataRequestToJSON(requestParameters.latestLogDataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LogDataRepresentationFromJSON));
    }

    /**
     * Returns log data for datarequest
     * Returns latest log entries
     */
    async getLatestLogData(latestLogDataRequest: LatestLogDataRequest): Promise<Array<LogDataRepresentation>> {
        const response = await this.getLatestLogDataRaw({ latestLogDataRequest: latestLogDataRequest });
        return await response.value();
    }

    /**
     * Returns log data for datarequest
     * Returns log data
     */
    async getLogDataRaw(requestParameters: GetLogDataRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.logDataRequest === null || requestParameters.logDataRequest === undefined) {
            throw new runtime.RequiredError('logDataRequest','Required parameter requestParameters.logDataRequest was null or undefined when calling getLogData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogDataRequestToJSON(requestParameters.logDataRequest),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns log data for datarequest
     * Returns log data
     */
    async getLogData(logDataRequest: LogDataRequest): Promise<Blob> {
        const response = await this.getLogDataRaw({ logDataRequest: logDataRequest });
        return await response.value();
    }

    /**
     * Returns operator prognosis monetary
     * Returns operator prognosis monetary
     */
    async getOperatorPrognosisMonetaryRaw(requestParameters: GetOperatorPrognosisMonetaryRequest): Promise<runtime.ApiResponse<OperatorPrognosisMonetaryRepresentation>> {
        if (requestParameters.operatorPrognosisMonetaryRequest === null || requestParameters.operatorPrognosisMonetaryRequest === undefined) {
            throw new runtime.RequiredError('operatorPrognosisMonetaryRequest','Required parameter requestParameters.operatorPrognosisMonetaryRequest was null or undefined when calling getOperatorPrognosisMonetary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/operator-prognosis-monetary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorPrognosisMonetaryRequestToJSON(requestParameters.operatorPrognosisMonetaryRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorPrognosisMonetaryRepresentationFromJSON(jsonValue));
    }

    /**
     * Returns operator prognosis monetary
     * Returns operator prognosis monetary
     */
    async getOperatorPrognosisMonetary(operatorPrognosisMonetaryRequest: OperatorPrognosisMonetaryRequest): Promise<OperatorPrognosisMonetaryRepresentation> {
        const response = await this.getOperatorPrognosisMonetaryRaw({ operatorPrognosisMonetaryRequest: operatorPrognosisMonetaryRequest });
        return await response.value();
    }

    /**
     * Get list of reimport markers for all generators of user
     * Get reimport markers
     */
    async getPendingDataReImportsRaw(): Promise<runtime.ApiResponse<Array<ReImportMarkerRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/data/reimports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReImportMarkerRepresentationFromJSON));
    }

    /**
     * Get list of reimport markers for all generators of user
     * Get reimport markers
     */
    async getPendingDataReImports(): Promise<Array<ReImportMarkerRepresentation>> {
        const response = await this.getPendingDataReImportsRaw();
        return await response.value();
    }

    /**
     * Returns pie data for datarequest
     * Returns pie data
     */
    async getPieDataRaw(requestParameters: GetPieDataRequest): Promise<runtime.ApiResponse<PieDataRepresentation>> {
        if (requestParameters.pieDataRequest === null || requestParameters.pieDataRequest === undefined) {
            throw new runtime.RequiredError('pieDataRequest','Required parameter requestParameters.pieDataRequest was null or undefined when calling getPieData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/pie`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PieDataRequestToJSON(requestParameters.pieDataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PieDataRepresentationFromJSON(jsonValue));
    }

    /**
     * Returns pie data for datarequest
     * Returns pie data
     */
    async getPieData(pieDataRequest: PieDataRequest): Promise<PieDataRepresentation> {
        const response = await this.getPieDataRaw({ pieDataRequest: pieDataRequest });
        return await response.value();
    }

    /**
     * Returns powercurve data for datarequest
     * Returns powercurve data
     */
    async getPowercurveDataRaw(requestParameters: GetPowercurveDataRequest): Promise<runtime.ApiResponse<Array<PowercurveDataRepresentation>>> {
        if (requestParameters.powercurveDataRequest === null || requestParameters.powercurveDataRequest === undefined) {
            throw new runtime.RequiredError('powercurveDataRequest','Required parameter requestParameters.powercurveDataRequest was null or undefined when calling getPowercurveData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/powercurve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PowercurveDataRequestToJSON(requestParameters.powercurveDataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PowercurveDataRepresentationFromJSON));
    }

    /**
     * Returns powercurve data for datarequest
     * Returns powercurve data
     */
    async getPowercurveData(powercurveDataRequest: PowercurveDataRequest): Promise<Array<PowercurveDataRepresentation>> {
        const response = await this.getPowercurveDataRaw({ powercurveDataRequest: powercurveDataRequest });
        return await response.value();
    }

    /**
     * Returns scatter data for datarequest
     * Returns scatter data
     */
    async getScatterDataRaw(requestParameters: GetScatterDataRequest): Promise<runtime.ApiResponse<Array<ScatterDataRepresentation>>> {
        if (requestParameters.scatterDataRequest === null || requestParameters.scatterDataRequest === undefined) {
            throw new runtime.RequiredError('scatterDataRequest','Required parameter requestParameters.scatterDataRequest was null or undefined when calling getScatterData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/scatter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScatterDataRequestToJSON(requestParameters.scatterDataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScatterDataRepresentationFromJSON));
    }

    /**
     * Returns scatter data for datarequest
     * Returns scatter data
     */
    async getScatterData(scatterDataRequest: ScatterDataRequest): Promise<Array<ScatterDataRepresentation>> {
        const response = await this.getScatterDataRaw({ scatterDataRequest: scatterDataRequest });
        return await response.value();
    }

    /**
     * Returns wind rose data for datarequest
     * Returns wind rose data
     */
    async getWindroseDataRaw(requestParameters: GetWindroseDataRequest): Promise<runtime.ApiResponse<WindroseDataRepresentation>> {
        if (requestParameters.windroseDataRequest === null || requestParameters.windroseDataRequest === undefined) {
            throw new runtime.RequiredError('windroseDataRequest','Required parameter requestParameters.windroseDataRequest was null or undefined when calling getWindroseData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/data/windrose`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WindroseDataRequestToJSON(requestParameters.windroseDataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WindroseDataRepresentationFromJSON(jsonValue));
    }

    /**
     * Returns wind rose data for datarequest
     * Returns wind rose data
     */
    async getWindroseData(windroseDataRequest: WindroseDataRequest): Promise<WindroseDataRepresentation> {
        const response = await this.getWindroseDataRaw({ windroseDataRequest: windroseDataRequest });
        return await response.value();
    }

    /**
     * Upload CSV data time series
     * Upload CSV data
     */
    async uploadCSVDataRaw(requestParameters: UploadCSVDataRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling uploadCSVData.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling uploadCSVData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["generators:write"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        if (requestParameters.data !== undefined) {
            formParams.append('data', requestParameters.data as any);
        }

        const response = await this.request({
            path: `/data/import/csv`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Upload CSV data time series
     * Upload CSV data
     */
    async uploadCSVData(type: UploadCSVDataTypeEnum, data: Blob): Promise<string> {
        const response = await this.uploadCSVDataRaw({ type: type, data: data });
        return await response.value();
    }

    /**
     * Upload CSV data for a solar park
     * Upload solar park data
     */
    async uploadSolarParkDataRaw(requestParameters: UploadSolarParkDataRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.parkMasterData === null || requestParameters.parkMasterData === undefined) {
            throw new runtime.RequiredError('parkMasterData','Required parameter requestParameters.parkMasterData was null or undefined when calling uploadSolarParkData.');
        }

        if (requestParameters.parkDataMonthly === null || requestParameters.parkDataMonthly === undefined) {
            throw new runtime.RequiredError('parkDataMonthly','Required parameter requestParameters.parkDataMonthly was null or undefined when calling uploadSolarParkData.');
        }

        if (requestParameters.parkDataDaily === null || requestParameters.parkDataDaily === undefined) {
            throw new runtime.RequiredError('parkDataDaily','Required parameter requestParameters.parkDataDaily was null or undefined when calling uploadSolarParkData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["generators:write"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.parkMasterData !== undefined) {
            formParams.append('parkMasterData', requestParameters.parkMasterData as any);
        }

        if (requestParameters.parkDataMonthly !== undefined) {
            formParams.append('parkDataMonthly', requestParameters.parkDataMonthly as any);
        }

        if (requestParameters.parkDataDaily !== undefined) {
            formParams.append('parkDataDaily', requestParameters.parkDataDaily as any);
        }

        const response = await this.request({
            path: `/data/import/solarparks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload CSV data for a solar park
     * Upload solar park data
     */
    async uploadSolarParkData(parkMasterData: Blob, parkDataMonthly: Blob, parkDataDaily: Blob): Promise<void> {
        await this.uploadSolarParkDataRaw({ parkMasterData: parkMasterData, parkDataMonthly: parkDataMonthly, parkDataDaily: parkDataDaily });
    }

}

/**
    * @export
    * @enum {string}
    */
export enum UploadCSVDataTypeEnum {
    MonthlyPlan = 'monthly_plan',
    MonthlyPlanBasicData = 'monthly_plan_basic_data',
    CommissionMarketvalues = 'commission_marketvalues'
}
